import { CheckInTasksModalButtonTasks } from './CheckInTasksModalButton.types';
import { ClassName, GADataType, I18nText } from '../../../../../types';
import { IconsEnum } from '../../../../../assets/icons/types';
import { CheckInTasksCacheKeys } from '../../../tasksTypes';

import { useCurrentUser } from '../../../../../auth/hooks/useAuth';

import { BatchCreateSmartContractSharesModalButton } from '../../../../smartContractShares/components/modalButtons/BatchCreateSmartContractSharesModalButton';

import { tasksKeys } from '../../../../../locales/keys';

interface CheckInTasksModalButtonProps {
  className?: ClassName;
  i18nText?: I18nText;
  icon?: IconsEnum;
  iconClassName?: ClassName;
  tasks: CheckInTasksModalButtonTasks;
  cacheKeys?: CheckInTasksCacheKeys;
  tooltipI18nText?: I18nText;
  afterCreate?: () => void;
}

function CheckInTasksModalButton({
  afterCreate,
  cacheKeys,
  className,
  dataGa,
  i18nText,
  icon,
  iconClassName,
  tasks,
  tooltipI18nText
}: CheckInTasksModalButtonProps & GADataType) {
  const currentUser = useCurrentUser();

  return (
    <BatchCreateSmartContractSharesModalButton
      afterCreate={afterCreate}
      cacheKeys={cacheKeys}
      className={className}
      dataGa={dataGa}
      defaultUsers={[currentUser]}
      i18nText={i18nText}
      i18nTitle={tasksKeys.buttons.checkIn}
      icon={icon}
      iconClassName={iconClassName}
      tasks={tasks}
      tooltipI18nText={tooltipI18nText}
    />
  );
}

export default CheckInTasksModalButton;
